import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from './assets/logo.svg';

const useStyles = makeStyles({
  footer: {
    backgroundColor: 'rgba(126, 156, 162, 0.87)',
    padding: '70px 160px',
    color: '#F4F4F4',
    '@media (max-width: 1500px)': {
      padding: '70px 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '20px 16px',
    },
  },
  footerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  footerLogo: {
    width: 213,
    '@media (max-width: 1200px)': {
      width: 121,
      marginBottom: 16,
    },
  },
  copyright: {
    fontSize: 14,
    '@media (max-width: 1200px)': {
      fontSize: 7,
    },
  },
  footerText: {
    fontSize: 20,
    marginLeft: 32,
    '@media (max-width: 1200px)': {
      fontSize: 13,
      marginLeft: 0,
      marginBottom: 16,
    },
  },
});

export default function Footer() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  return (
    <div className={classes.footer}>
      <div className={classes.footerTop}>
        <Logo className={classes.footerLogo} />
        <Typography className={classes.footerText}>
          2625 Middlefield Road, Suite 428
          <br />
          Palo Alto, CA 94306
          <br />
          USA
          <br />
          <br />
          +1 650 461 9113
          {!isMobile && (
            <>
              <br />
              <br />
              info@toolla.net
            </>
          )}
        </Typography>
        <Typography className={classes.footerText}>
          Gdański Park Naukowo-Technologiczny
          <br />
          ul. Trzy Lipy 3, budynek B, pok. 2.8.2
          <br />
          80-172 Gdańsk,
          <br />
          Poland
          <br />
          <br />
          +48 502 765 356
          {isMobile && (
            <>
              <br />
              <br />
              info@toolla.net
            </>
          )}
        </Typography>
      </div>
      <Typography className={classes.copyright}>
        Copyright © 2015-2021 Toolla Inc. All rights reserved.
      </Typography>
    </div>
  );
}
