import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import innobaltica from '../assets/innobaltica.svg';
import cisco from '../assets/cisco.svg';
import techdata from '../assets/techdata.png';
import verseon from '../assets/verseon.png';
import avnet from '../assets/avnet.svg';
import wellsa from '../assets/wellsa.svg';

const useStyles = makeStyles({
  customers: {
    backgroundColor: 'white',
    padding: '40px 160px',
    '@media (max-width: 1500px)': {
      padding: '40px 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '40px 16px',
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 24,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: 60,
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      padding: 0,
    },
  },
  innobaltica: {
    width: 400,
    height: 41,
  },
  cisco: {
    width: 350,
    height: 241,
    margin: '-40px 0',
    '@media (max-width: 1200px)': {
      margin: '-70px 0',
    },
  },
  techdata: {
    width: 400,
    height: 91,
  },
  verseon: {
    width: 350,
    height: 105,
  },
  avnet: {
    width: 300,
    height: 58,
  },
  wellsa: {
    width: 250,
    height: 105,
  },
  logoWrapper: {
    width: 400,
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1500px)': {
      width: 350,
      transform: 'scale(0.875)',
    },
    '@media (max-width: 1200px)': {
      transform: 'scale(0.6)',
      margin: '16px 0',
    },
  },
});

export default function Customers() {
  const classes = useStyles();
  return (
    <div className={classes.customers}>
      <Typography className={classes.sectionHeader}>
        Our Customers
      </Typography>
      <div className={classes.logos}>
        <div className={classes.logoWrapper}>
          <img src={innobaltica} alt="InnoBaltica" className={classes.innobaltica} />
        </div>
        <div className={classes.logoWrapper}>
          <img src={cisco} alt="Cisco" className={classes.cisco} />
        </div>
        <div className={classes.logoWrapper}>
          <img src={techdata} alt="Tech Data" className={classes.techdata} />
        </div>
      </div>
      <div className={classes.logos}>
        <div className={classes.logoWrapper}>
          <img src={verseon} alt="Verseon" className={classes.verseon} />
        </div>
        <div className={classes.logoWrapper}>
          <img src={avnet} alt="Avnet" className={classes.avnet} />
        </div>
        <div className={classes.logoWrapper}>
          <img src={wellsa} alt="Wellsa" className={classes.wellsa} />
        </div>
      </div>
    </div>
  );
}
