import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Footer from '../Footer';
import Nav from '../Nav';
import brandingBg from '../assets/branding-bg.png';
import brandingTop from '../assets/branding-top.png';
import katanaOne from '../assets/katana-one.png';
import katanaTwo from '../assets/katana-two.png';
import katanaThree from '../assets/katana-three.png';
import katanaFour from '../assets/katana-four.png';
import nucleusOne from '../assets/nucleus-one.png';
import nucleusTwo from '../assets/nucleus-two.png';
import catapultBg from '../assets/catapult-bg.png';
import catapultLogo from '../assets/catapult-logo.png';
import ampOne from '../assets/branding-amp-one.png';
import ampTwo from '../assets/branding-amp-two.png';
import ampThree from '../assets/branding-amp-three.png';
import ampFour from '../assets/branding-amp-four.png';

const useStyles = makeStyles({
  content: {
    backgroundColor: 'white',
  },
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.79)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${brandingBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 563,
    height: 407,
    backgroundImage: `url(${brandingTop})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    flexShrink: 0,
    margin: '180px 50px 150px -50px',
    '@media (max-width: 1200px)': {
      width: '100%',
      height: 237,
      margin: '150px 0 100px 0',
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 180,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1200px)': {
      margin: '0 0 120px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  textSection: {
    paddingTop: 150,
    paddingBottom: 150,
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  katana: {
    width: '100%',
    maxWidth: 1920,
    margin: '0 auto',
    height: '1040px',
    backgroundImage: `url(${katanaTwo})`,
    backgroundSize: '1920px 1040px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    '@media (max-width: 1200px)': {
      height: 725,
      backgroundSize: '1357px 725px',
      backgroundPosition: 'left',
    },
  },
  katanaOne: {
    width: 297,
    height: 215,
    position: 'absolute',
    left: 14,
    top: 23,
    backgroundColor: 'white',
    '@media (max-width: 1919px)': {
      left: 0,
    },
    '@media (max-width: 1200px)': {
      width: 210,
      height: 152,
    },
  },
  katanaThree: {
    width: 415,
    height: 841,
    position: 'absolute',
    left: '50%',
    top: 150,
    transform: 'translateX(-50%)',
    '@media (max-width: 1200px)': {
      width: 167,
      height: 338,
      top: '32%',
    },
  },
  katanaFour: {
    width: 228,
    height: 207,
    position: 'absolute',
    left: '70%',
    bottom: 0,
    '@media (max-width: 1200px)': {
      width: 120,
      height: 109,
      bottom: 30,
      left: 'auto',
      right: 30,
    },
  },
  nucleus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '120px 80px',
    '@media (max-width: 1200px)': {
      flexDirection: 'column-reverse',
      margin: '60px 20px',
    },
  },
  nucleusOne: {
    width: '30%',
    maxWidth: 437,
    marginRight: 30,
    '@media (max-width: 1200px)': {
      width: 181,
      marginRight: 0,
      marginLeft: 'auto',
      marginTop: 40,
    },
  },
  nucleusTwo: {
    width: '70%',
    maxWidth: 909,
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  },
  catapult: {
    width: '100%',
    maxWidth: 1920,
    margin: '0 auto',
    height: 980,
    backgroundImage: `url(${catapultBg})`,
    backgroundSize: '1920px 980px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    position: 'relative',
    marginBottom: 60,
    '@media (max-width: 1200px)': {
      height: 377,
      backgroundSize: '659px 377px',
      backgroundPosition: '-240px 0',
    },
  },
  catapultLogo: {
    width: 1075,
    position: 'absolute',
    top: 190,
    right: 200,
    '@media (max-width: 1200px)': {
      width: 360,
      right: 20,
      top: 70,
    },
  },
  row: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  ampImage: {
    width: '50%',
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  },
});

export default function Amp() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Branding and design services">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              Branding and design services
            </Typography>
          </div>
        </Section>
        <div className={classes.content}>
          <Section className={classes.textSection}>
            <Typography className={classes.sectionText}>
              Toolla designed logos and other branding elements for customer programs and/or
              marketing events held jointly by Cisco, Avnet, and Evernote, including Katana
              marketing conference, Nucleus, AMP and Catapult customer programs.
            </Typography>
          </Section>
          <div className={classes.katana}>
            <img src={katanaOne} alt="" className={classes.katanaOne} />
            <img src={katanaThree} alt="" className={classes.katanaThree} />
            <img src={katanaFour} alt="" className={classes.katanaFour} />
          </div>
          <div className={classes.nucleus}>
            <img src={nucleusOne} alt="" className={classes.nucleusOne} />
            <img src={nucleusTwo} alt="" className={classes.nucleusTwo} />
          </div>
          <div className={classes.catapult}>
            <img src={catapultLogo} alt="" className={classes.catapultLogo} />
          </div>
          <div className={classes.row}>
            <img src={ampOne} alt="" className={classes.ampImage} />
            <img src={ampTwo} alt="" className={classes.ampImage} />
          </div>
          <div className={classes.row}>
            <img src={ampThree} alt="" className={classes.ampImage} />
            <img src={ampFour} alt="" className={classes.ampImage} />
          </div>
        </div>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
