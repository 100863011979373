import React, { useEffect, useState } from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fade, IconButton, Link, Modal, useMediaQuery,
} from '@material-ui/core';
import { ReactComponent as LogoWhite } from './assets/logo-white.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Menu } from './assets/menu.svg';

const useStyles = makeStyles((theme) => ({
  nav: {
    paddingTop: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
    '@media (max-width: 1200px)': {
      paddingTop: 18,
    },
  },
  logo: {
    height: 54,
    marginBottom: 12,
    '@media (max-width: 1200px)': {
      width: 121,
      height: 31,
      marginBottom: 4,
    },
  },
  logoLink: {
    WebkitTapHighlightColor: 'transparent',
  },
  blur: {
    '@media (max-width: 1200px)': {
      position: 'fixed',
      top: 65,
      left: 0,
      width: '100%',
      height: 'calc(100% - 65px)',
      background: '#2d5b6d',
      '@supports (backdrop-filter: blur(50px))': {
        background: 'rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(50px)',
      },
      '@supports (-webkit-backdrop-filter: blur(50px))': {
        background: 'rgba(0, 0, 0, 0.1)',
        WebkitBackdropFilter: 'blur(50px)',
      },
    },
  },
  links: {
    display: 'flex',
    margin: '0 -34px',
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width: 1200px)': {
      position: 'fixed',
      top: 65,
      left: 0,
      width: '100%',
      height: 'calc(100% - 65px)',
      flexDirection: 'column',
      margin: 0,
      alignItems: 'flex-start',
      paddingTop: 32,
    },
  },
  navLink: {
    position: 'relative',
    padding: '8px',
    margin: '0 26px',
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    lineHeight: 'normal',
    textShadow: '1px 1px 2px #002f35',
    transition: theme.transitions.create('color'),
    WebkitTapHighlightColor: 'transparent',
    '&:hover': {
      textDecoration: 'none',
      color: 'rgba(255, 255, 255, 0.6)',
    },
    '&:focus': {
      outline: 'auto',
    },
    '@media (max-width: 1500px)': {
      margin: '0 8px',
    },
    '@media (max-width: 1200px)': {
      margin: '12px 8px',
      textShadow: 'none',
    },
  },
  active: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
}));

interface CustomTransitionProps {
  in: boolean;
  onEnter?: () => void;
  onExited?: () => void;
  setMenuOpen: (open: boolean) => void;
}

const CustomTransition = React.forwardRef<
  HTMLDivElement,
  CustomTransitionProps
>((props: CustomTransitionProps, ref) => {
  const {
    in: inProp, onEnter, onExited, setMenuOpen,
  } = props;
  const classes = useStyles();
  return (
    <div ref={ref} tabIndex={-1}>
      <Fade in={inProp} onEnter={onEnter} onExited={onExited}>
        <div className={classes.blur} />
      </Fade>
      <Fade in={inProp}>
        <div className={classes.links}>
          <Link
            className={classes.navLink}
            component={NavLink}
            to="/#about-us"
            onClick={() => setMenuOpen(false)}
          >
            About us
          </Link>
          <Link
            className={classes.navLink}
            component={NavLink}
            to="/#services"
            onClick={() => setMenuOpen(false)}
          >
            Services
          </Link>
          {/*
          <Link
            component="button"
            className={classes.navLink}
          >
            How we work
          </Link>
        */}
          <Link
            className={classes.navLink}
            component={NavLink}
            exact
            activeClassName={classes.active}
            to="/our-work"
            onClick={() => setMenuOpen(false)}
          >
            Portfolio
          </Link>
          <Link
            className={classes.navLink}
            component={NavLink}
            to="/#careers"
            onClick={() => setMenuOpen(false)}
          >
            Careers
          </Link>
        </div>
      </Fade>
    </div>
  );
});

CustomTransition.defaultProps = {
  onEnter: () => {},
  onExited: () => {},
};

export default function Nav() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    setMenuOpen(false);
  }, [isMobile]);
  const fadeIn = !isMobile || menuOpen;
  return (
    <div className={classes.nav}>
      <RouterLink to="/" className={classes.logoLink}>
        <LogoWhite className={classes.logo} />
      </RouterLink>
      {isMobile && (
        <IconButton
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {menuOpen ? (
            <Close />
          ) : (
            <Menu />
          )}
        </IconButton>
      )}
      {isMobile ? (
        <Modal
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          BackdropProps={{ invisible: true }}
          closeAfterTransition
        >
          <CustomTransition in={fadeIn} setMenuOpen={setMenuOpen} />
        </Modal>
      ) : <CustomTransition in={fadeIn} setMenuOpen={setMenuOpen} />}
    </div>
  );
}
