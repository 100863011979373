import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Footer from '../Footer';
import Nav from '../Nav';
import ampBg from '../assets/amp-bg.png';
import ampOne from '../assets/amp-one.png';
import ampMiddle from '../assets/amp-middle.png';
import ampBottomOne from '../assets/amp-bottom-one.png';
import ampBottomTwo from '../assets/amp-bottom-two.png';
import ampBottomThree from '../assets/amp-bottom-three.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${ampBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 'calc(100% - 500px)',
    maxWidth: 800,
    height: 588,
    backgroundImage: `url(${ampOne})`,
    backgroundSize: '995px 588px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    flexShrink: 0,
    margin: '100px -40px 80px -160px',
    '@media (max-width: 1200px)': {
      width: '100%',
      maxWidth: '100%',
      height: 400,
      margin: '100px 0 60px -16px',
      backgroundSize: '677px 400px',
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 150,
    maxWidth: 900,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1200px)': {
      margin: '0 0 40px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  quoteSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 80,
    paddingBottom: 80,
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  quoteText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  quoteAuthor: {
    fontSize: 21,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  challenge: {
    marginTop: 0,
    marginBottom: 70,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    display: 'flex',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 70,
      marginTop: 0,
      marginBottom: 25,
      flexDirection: 'column',
    },
  },
  challengeTextContainer: {
    width: '50%',
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  middleImage: {
    width: '65%',
    maxWidth: 816,
    marginBottom: -400,
    marginRight: -40,
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    '@media (max-width: 1200px)': {
      width: '100%',
      margin: '20px 0 0',
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    width: '50%',
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
      order: 2,
    },
  },
  bottom: {
    padding: '0 20px 40px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'rgb(126, 156, 162, 0.09)',
    position: 'relative',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  bottomImage: {
    maxWidth: 301,
    marginTop: -60,
    '@media (max-width: 1200px)': {
      width: 168,
      marginBottom: 40,
      marginTop: 0,
    },
  },
});

export default function Amp() {
  const classes = useStyles();
  return (
    <DocumentTitle title="AMP: one-stop portal for resellers of Cisco data center products">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              AMP: one-stop portal for resellers of Cisco data center products
            </Typography>
          </div>
        </Section>
        <Section className={classes.quoteSection}>
          <Typography className={classes.quoteText}>
            &quot;It has been close to a year that we hired Toolla to help us build an app for
            Cisco / Avnet, and I couldn&apos;t be more happy with the alliance. They are a
            passionate team of engineers and cutting-edge designers. The folks at Toolla are
            extremely knowledgeable, diligent, and are extremely current on the latest trends in the
            app world. They built us a fabulous mobile app, that was created in record time.
            We are extremely impressed with Toolla, and are looking forward to a stronger
            partnership.&quot;
          </Typography>
          <Typography className={classes.quoteAuthor}>
            Marketing Manager
            <br />
            Cisco
          </Typography>
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              Cisco and Avnet needed a platform which would provide their partners with
              resources related to Cisco data center products, including product specifications,
              information about marketing events, or access to sales support staff. The goal of
              the AMP platform was also to train and certify the resellers about data center
              products in an engaging and effective manner.
            </Typography>
          </div>
          <img src={ampMiddle} alt="" className={classes.middleImage} />
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              Toolla was tasked with developing iOS and Android mobile apps, as well as
              redesigning and reimplementing the initial web portal for the AMP platform.
              The collaboration produced mobile apps and a new portal which included quizzes
              for resellers, tools for scheduling appointments with the regional representatives
              and support staff, and easy access to all marketing resources. The apps encourage
              user activities through a gamification approach and attractive incentives in order
              to achieve wider and faster dissemination of information about the available
              products and upcoming events.
            </Typography>
          </div>
        </Section>
        <div className={classes.bottom}>
          <img src={ampBottomOne} alt="" className={classes.bottomImage} />
          <img src={ampBottomTwo} alt="" className={classes.bottomImage} />
          <img src={ampBottomThree} alt="" className={classes.bottomImage} />
        </div>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
