import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Footer from '../Footer';
import Nav from '../Nav';
import blockrulesBg from '../assets/blockrules-bg.jpg';
import blockrulesTop from '../assets/blockrules-top.png';
import blockrulesChallengeOne from '../assets/blockrules-challenge-one.png';
import blockrulesChallengeTwo from '../assets/blockrules-challenge-two.png';
import blockrulesSolution from '../assets/blockrules-solution.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${blockrulesBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 421,
    maxWidth: '25%',
    height: 841,
    backgroundImage: `url(${blockrulesTop})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    flexShrink: 0,
    margin: '60px 80px -150px',
    '@media (max-width: 1750px)': {
      margin: '60px 20px -150px -100px',
    },
    '@media (max-width: 1500px)': {
      margin: '60px 20px -150px -40px',
    },
    '@media (max-width: 1200px)': {
      margin: '40px auto',
      maxWidth: '100%',
      width: 240,
      height: 485,
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 180,
    marginLeft: 40,
    maxWidth: 1000,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1500px)': {
      marginTop: 120,
    },
    '@media (max-width: 1200px)': {
      margin: '0 0 20px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  quoteSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 180,
    paddingBottom: 80,
    '@media (max-width: 1700px)': {
      paddingTop: 100,
    },
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  quoteText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  quoteAuthor: {
    fontSize: 21,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  challenge: {
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 1200px)': {
      marginTop: 0,
      paddingTop: 30,
      paddingBottom: 50,
      flexWrap: 'wrap',
    },
  },
  challengeTextContainer: {
    width: '40%',
    flexShrink: 0,
    maxWidth: 670,
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      maxWidth: '100%',
      paddingRight: 0,
    },
  },
  challengeImageOne: {
    width: '30%',
    maxWidth: 360,
    marginRight: 100,
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    marginBottom: -300,
    '@media (max-width: 1500px)': {
      marginRight: 20,
    },
    '@media (max-width: 1200px)': {
      width: 'calc(50% - 10px)',
      marginBottom: 0,
      marginTop: 20,
      marginLeft: 0,
    },
  },
  challengeImageTwo: {
    width: '30%',
    maxWidth: 360,
    marginBottom: -420,
    alignSelf: 'flex-end',
    '@media (max-width: 1200px)': {
      width: 'calc(50% - 10px)',
      marginBottom: -130,
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 40,
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 1200px)': {
      paddingTop: 100,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    width: '40%',
    flexShrink: 0,
    maxWidth: 670,
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  solutionImage: {
    width: '30%',
    maxWidth: 360,
    margin: '-160px auto 0',
    '@media (max-width: 1200px)': {
      width: 'auto',
      maxHeight: 330,
      margin: '20px auto',
    },
  },
  link: {
    fontSize: 21,
    fontWeight: 300,
    color: '#1388AA',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      margin: '20px 0 0',
    },
  },
});

export default function InterconnectTwo() {
  const classes = useStyles();
  return (
    <DocumentTitle
      title="BlockRules: the first comprehensive, regulatory compliant blockchain securities platform"
    >
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              BlockRules: the&nbsp;first comprehensive, regulatory compliant blockchain
              securities platform
            </Typography>
          </div>
        </Section>
        <Section className={classes.quoteSection}>
          <Typography className={classes.quoteText}>
            “Toolla.net provided off shore engineering services for both of my startups
            Wellsa.me and Blockrules.com. The team I worked with has provided exceptional
            software engineering support. They are expertise in full stack - web, mobile app
            and micro services development. Toolla team is very professional. They always
            delivered projects on time and the quality of work is exceptional. I would definitely
            hire their team if I need off shore engineering services in the future.”
          </Typography>
          <Typography className={classes.quoteAuthor}>
            Pramod Madabhushi
            <br />
            VP Engineering
          </Typography>
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              BlockRules Ltd. found that many transactions involving token securities
              (including cryptocurrencies) issued on the blockchain, particularly those executed
              across different markets, often do not comply with local regulatory requirements.
              The key reason for this being that token investors lack easy-to-use tools which
              would enforce such compliance. BlockRules platform is intended to bring regulatory
              compliance to trading transactions for blockchain securities at various jurisdictions
              around the globe.
            </Typography>
          </div>
          <img src={blockrulesChallengeOne} alt="" className={classes.challengeImageOne} />
          <img src={blockrulesChallengeTwo} alt="" className={classes.challengeImageTwo} />
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              Toolla’s task was to design and develop a user-friendly process which would guide
              the investors through the entire compliance process, appropriate for a particular
              jurisdiction. We implemented this process, involving AI-based mechanisms for
              automated assessment of identification documents, as well as other know-your-customer
              (KYC) and anti-money-laundering (AML) compliance components in iOS and Android mobile
              apps.
            </Typography>
            <Typography className={classes.sectionText}>
              The apps designed and implemented by Toolla also included a full-featured electronic
              wallet for securely generating, importing and storing keys, and allowing the users
              to cryptographically sign and place token auction bids and securely transfer their
              tokens.
            </Typography>
            <Link
              href="https://www.blockrules.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              www.blockrules.com
            </Link>
          </div>
          <img src={blockrulesSolution} alt="" className={classes.solutionImage} />
        </Section>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
