import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ReactComponent as Tanbuu } from '../assets/tanbuu.svg';
import backgroundOne from '../assets/background-one.jpg';
import lines from '../assets/lines.png';
import Nav from '../Nav';

const useStyles = makeStyles((theme) => ({
  firstSection: {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundOne})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '0 160px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width: 1500px)': {
      padding: '0 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '0 16px',
    },
  },
  linesContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    '@media (max-width: 1500px)': {
      marginLeft: -80,
    },
    '@media (max-width: 1200px)': {
      marginLeft: -220,
      marginTop: -30,
    },
  },
  lines: {
    width: 1920,
    height: 1080,
    backgroundImage: `url(${lines})`,
  },
  '@keyframes lineWidth': {
    from: {
      width: 0,
    },
    to: {
      width: 670,
    },
  },
  '@keyframes lineWidthLaptop': {
    from: {
      width: 80,
    },
    to: {
      width: 670,
    },
  },
  '@keyframes lineWidthMobile': {
    from: {
      width: 220,
    },
    to: {
      width: 500,
    },
  },
  whiteLine: {
    position: 'absolute',
    top: 109,
    left: 0,
    width: 670,
    height: 2,
    backgroundColor: 'white',
    animation: '2s ease-in-out $lineWidth',
    '@media (max-width: 1500px)': {
      animationName: '$lineWidthLaptop',
    },
    '@media (max-width: 1200px)': {
      animationName: '$lineWidthMobile',
      width: 500,
    },
  },
  '@keyframes dotSize': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
  firstWhiteDot: {
    position: 'absolute',
    top: 101,
    left: 494,
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'white',
    animation: '2s ease-in-out $dotSize',
  },
  secondWhiteDot: {
    position: 'absolute',
    top: 96,
    left: 670,
    width: 28,
    height: 28,
    borderRadius: '50%',
    backgroundColor: 'white',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  firstSectionContent: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    zIndex: 10,
    '@media (max-width: 1200px)': {
      marginLeft: 0,
    },
  },
  header: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    lineHeight: 1.2,
    marginBottom: 24,
    '@media (max-width: 1200px)': {
      fontSize: 44,
    },
  },
  subheader: {
    fontSize: 30,
    fontWeight: 700,
    color: 'black',
    marginBottom: 72,
    opacity: 0,
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  subheaderVisible: {
    opacity: 1,
  },
  tanbuuLink: {
    padding: '24px 44px',
    backgroundColor: 'white',
    borderRadius: 50,
    fontSize: 28,
    fontWeight: 300,
    color: 'black',
    display: 'inline-flex',
    alignItems: 'center',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
    '@media (max-width: 1200px)': {
      fontSize: 16,
      padding: '12px 22px',
    },
  },
  tanbuu: {
    width: 154,
    height: 65,
    margin: '-19px 12px -10px 4px',
    '@media (max-width: 1200px)': {
      width: 88,
      height: 37,
      margin: '-12px 6px -5px 2px',
    },
  },
}));

export default function FirstSection() {
  const classes = useStyles();
  const [subheaderVisible, setSubheaderVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setSubheaderVisible(true), 2000);
  }, []);
  return (
    <div className={classes.firstSection}>
      <Nav />
      <div className={classes.linesContainer}>
        <div className={classes.lines} />
        <div className={classes.whiteLine} />
        <div className={classes.firstWhiteDot} />
        <div className={classes.secondWhiteDot} />
      </div>
      <div className={classes.firstSectionContent}>
        <div>
          <Typography className={classes.header}>
            Custom AI
            <br />
            & Machine Learning Apps
          </Typography>
          <Typography
            className={classNames(classes.subheader, {
              [classes.subheaderVisible]: subheaderVisible,
            })}
          >
            to automate operations, engage customers,
            <br />
            and effectively compete in a digital world
          </Typography>
        </div>
        <Link
          href="https://www.youtube.com/watch?v=0IJMbYfE4xw"
          target="_blank"
          rel="noreferrer noopener"
          className={classes.tanbuuLink}
        >
          Introducing
          <Tanbuu className={classes.tanbuu} />
          AI scheduler
        </Link>
      </div>
    </div>
  );
}
