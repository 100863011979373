import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    paddingLeft: 160,
    paddingRight: 160,
    '@media (max-width: 1500px)': {
      paddingLeft: 80,
      paddingRight: 80,
    },
    '@media (max-width: 1200px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
});

export default function Section(props: Props) {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classNames(className, classes.root)} {...rest}>
      {children}
    </div>
  );
}

Section.defaultProps = {
  children: null,
};
