import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';
import DocumentTitle from 'react-document-title';
import Footer from '../Footer';
import Nav from '../Nav';
import Section from '../Section';
import tanbuuBg from '../assets/tanbuu-bg.jpg';
import tanbuuTop from '../assets/tanbuu-top.png';
import tanbuuMiddle from '../assets/tanbuu-middle.png';
import tanbuuBgTwo from '../assets/tanbuu-bg-two.png';
import tanbuuBottomOne from '../assets/tanbuu-bottom-one.png';
import tanbuuBottomTwo from '../assets/tanbuu-bottom-two.png';
import tanbuuBottomThree from '../assets/tanbuu-bottom-three.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.31)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${tanbuuBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 414,
    height: 839,
    backgroundImage: `url(${tanbuuTop})`,
    backgroundSize: 'contain',
    flexShrink: 0,
    margin: '60px 80px -40px',
    '@media (max-width: 1200px)': {
      margin: '40px auto',
      width: 239,
      height: 485,
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 200,
    maxWidth: 900,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    '@media (max-width: 1200px)': {
      margin: '0 0 20px',
      fontSize: 44,
    },
  },
  challenge: {
    paddingTop: 150,
    paddingBottom: 110,
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 50,
    },
  },
  challengeTextContainer: {
    width: '35%',
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    width: '35%',
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
      order: 2,
    },
  },
  middleImage: {
    width: '65%',
    maxWidth: 1061,
    marginTop: -270,
    marginRight: -40,
    marginLeft: 'auto',
    '@media (max-width: 1500px)': {
      marginTop: -200,
    },
    '@media (max-width: 1200px)': {
      width: '100%',
      margin: '-55px 0 30px',
    },
  },
  link: {
    display: 'inline-block',
    marginTop: 40,
    fontSize: 21,
    fontWeight: 300,
    color: '#1388AA',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginTop: 20,
    },
  },
  bottom: {
    padding: '80px 20px 40px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.44) 0%, rgba(128, 128, 128, 0.44) 100%)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${tanbuuBgTwo})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  bottomImage: {
    maxWidth: 374,
    '@media (max-width: 1200px)': {
      width: 168,
      marginBottom: 40,
    },
  },
});

export default function Tanbuu() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Tanbuu: AI meeting scheduler">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              Tanbuu:
              <br />
              AI meeting scheduler
            </Typography>
          </div>
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              At Toolla, we found ourselves spending way too much time scheduling meetings
              with our customers - an all too familiar problem. We wanted to stop endlessly
              browsing our calendars and sending emails back and forth. We wanted a tool to
              quickly find a good time to meet. Nothing in the market satisfied us.
            </Typography>
          </div>
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              To meet this challenge we developed Tanbuu - a custom Machine Learning (ML)
              prediction engine that instantly finds good times to meet, reduces interactions
              between participants, and, most importantly, keeps their calendars private. Tanbuu
              has allowed us to cut down on meeting-related emails by 90% and improved our
              customer relationships!
            </Typography>
            <Link
              href="https://tanbuu.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              www.tanbuu.com
            </Link>
          </div>
          <img src={tanbuuMiddle} alt="" className={classes.middleImage} />
        </Section>
        <div className={classes.bottom}>
          <img src={tanbuuBottomOne} alt="" className={classes.bottomImage} />
          <img src={tanbuuBottomTwo} alt="" className={classes.bottomImage} />
          <img src={tanbuuBottomThree} alt="" className={classes.bottomImage} />
        </div>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
