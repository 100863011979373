import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Nav from '../Nav';
import ourWork from '../assets/our-work.jpg';
import ProjectTile from './ProjectTile';
import tanbuuBg from '../assets/tanbuu-bg.jpg';
import tanbuuImage from '../assets/tanbuu-image.png';
import wellsaBg from '../assets/wellsa-bg.jpg';
import wellsaImage from '../assets/wellsa-image.png';
import interconnectOneImage from '../assets/interconnect-one-image.png';
import interconnectTwoBg from '../assets/interconnect-two-bg.jpg';
import interconnectTwoImage from '../assets/interconnect-two-image.png';
import blockrulesBg from '../assets/blockrules-bg.jpg';
import blockrulesLeft from '../assets/blockrules-left.png';
import blockrulesRight from '../assets/blockrules-right.png';
import ampBg from '../assets/amp-bg.png';
import ampOne from '../assets/amp-one.png';
import ampTwo from '../assets/amp-two.png';
import brandingBg from '../assets/branding-bg.png';
import brandingImage from '../assets/branding-image.png';
import Footer from '../Footer';

const useStyles = makeStyles({
  headerContainer: {
    height: 430,
    position: 'relative',
    backgroundColor: 'rgba(0, 71, 103, 0.66)',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 160px',
    '@media (max-width: 1500px)': {
      padding: '0 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '0 16px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${ourWork})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  header: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    margin: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 44,
      margin: 'auto 0',
    },
  },
  projects: {
    margin: '50px 160px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1500px)': {
      margin: '50px 80px',
    },
    '@media (max-width: 1200px)': {
      margin: '50px 16px',
    },
  },
  tanbuuHeader: {
    paddingTop: 187,
    paddingBottom: 20,
    '@media (max-width: 1200px)': {
      paddingTop: 30,
    },
  },
  tanbuuImage: {
    margin: '45px 20px 0 auto',
    width: 825,
    height: 580,
    backgroundImage: `url(${tanbuuImage})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    order: 3,
    '@media (max-width: 1200px)': {
      margin: '45px 0 0 40px',
      width: 'calc(100% - 40px)',
      backgroundSize: 'auto 100%',
      order: 1,
    },
  },
  wellsaHeader: {
    paddingTop: 346,
    paddingBottom: 16,
    '@media (max-width: 1600px)': {
      paddingTop: 220,
    },
    '@media (max-width: 1200px)': {
      paddingTop: 30,
    },
  },
  wellsaImage: {
    margin: '36px 100px 16px 94px',
    width: 647,
    height: 572,
    backgroundImage: `url(${wellsaImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    flexShrink: 0,
    '@media (max-width: 1720px)': {
      margin: '36px 20px 16px 40px',
    },
    '@media (max-width: 1200px)': {
      backgroundPosition: 'left',
      flexShrink: 1,
      margin: '45px 0 0 40px',
      width: 'calc(100% - 40px)',
      backgroundSize: 'auto 100%',
    },
  },
  interconnectOneHeader: {
    paddingTop: 200,
    paddingBottom: 20,
    '@media (max-width: 1700px)': {
      paddingTop: 120,
    },
    '@media (max-width: 1200px)': {
      paddingTop: 0,
    },
  },
  interconnectOneImage: {
    margin: '45px 20px 24px auto',
    width: 925,
    height: 567,
    backgroundImage: `url(${interconnectOneImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    order: 3,
    '@media (max-width: 1200px)': {
      backgroundPosition: 'left',
      margin: '24px 0 -10px 40px',
      width: 'calc(100% - 40px)',
      backgroundSize: 'auto 100%',
      order: 1,
    },
  },
  interconnectTwoHeader: {
    paddingTop: 200,
    paddingBottom: 20,
    '@media (max-width: 1700px)': {
      paddingTop: 120,
    },
    '@media (max-width: 1200px)': {
      paddingTop: 0,
    },
  },
  interconnectTwoImage: {
    margin: '45px 20px 24px auto',
    width: 925,
    height: 567,
    backgroundImage: `url(${interconnectTwoImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    order: 3,
    '@media (max-width: 1200px)': {
      backgroundPosition: 'left',
      margin: '24px 0 -10px 20px',
      width: 'calc(100% - 20px)',
      backgroundSize: 'auto 100%',
      order: 1,
    },
  },
  blockrulesHeader: {
    paddingTop: 153,
    paddingBottom: 20,
    '@media (max-width: 1700px)': {
      paddingTop: 40,
    },
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
  },
  blockrulesLeft: {
    margin: '0 50px 0 160px',
    width: 700,
    height: 625,
    backgroundImage: `url(${blockrulesLeft})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    order: 1,
    '@media (max-width: 1800px)': {
      margin: '0 0 0 40px',
    },
    '@media (max-width: 1400px)': {
      width: 500,
    },
    '@media (max-width: 1200px)': {
      backgroundPosition: 'left bottom',
      margin: '0 0 0 80px',
      width: 'calc(100% - 80px)',
      backgroundSize: '292px 450px',
    },
  },
  blockrulesRight: {
    margin: '125px 0 0 auto',
    width: 320,
    height: 500,
    backgroundImage: `url(${blockrulesRight})`,
    backgroundSize: '313px 623px',
    order: 3,
    '@media (max-width: 1650px)': {
      display: 'none',
    },
  },
  ampHeader: {
    paddingTop: 120,
    paddingBottom: 100,
    '@media (max-width: 1200px)': {
      paddingTop: 20,
      paddingBottom: 40,
    },
  },
  ampLeft: {
    width: 900,
    height: 531,
    backgroundImage: `url(${ampOne})`,
    backgroundSize: '900px 531px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    marginTop: 50,
    '@media (max-width: 1500px)': {
      width: 600,
    },
    '@media (max-width: 1200px)': {
      width: 'calc(100% - 40px)',
      margin: '0 0 0 40px',
      backgroundPosition: 'left center',
      backgroundSize: '450px 266px',
      marginTop: 30,
    },
  },
  ampRight: {
    width: 329,
    height: 455,
    backgroundImage: `url(${ampTwo})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    order: 3,
    flexShrink: 0,
    marginTop: 90,
    marginRight: 20,
    '@media (max-width: 1500px)': {
      flexShrink: 1,
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  brandingHeader: {
    paddingTop: 190,
    paddingBottom: 100,
    '@media (max-width: 1200px)': {
      paddingTop: 20,
      paddingBottom: 40,
    },
  },
  brandingImage: {
    width: '100%',
    maxWidth: 900,
    height: 437,
    backgroundImage: `url(${brandingImage})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    order: 2,
    marginTop: 90,
    marginRight: 55,
    '@media (max-width: 1200px)': {
      order: 1,
      height: 170,
      backgroundSize: 'contain',
      marginTop: 140,
      marginBottom: 125,
    },
  },
});

export default function OurWork() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Our Work">
      <div>
        <div className={classes.headerContainer}>
          <Nav />
          <Typography className={classes.header}>
            Our Work
          </Typography>
        </div>
        <div className={classes.projects}>
          <ProjectTile
            backgroundColor="rgba(0, 0, 0, 0.24)"
            backgroundUrl={tanbuuBg}
            headerText={<>Tanbuu: AI&nbsp;meeting scheduler</>}
            headerClassName={classes.tanbuuHeader}
            link="/our-work/tanbuu"
            rightImage={<div className={classes.tanbuuImage} />}
          />
          <ProjectTile
            backgroundColor="rgba(0, 0, 0, 0.15)"
            backgroundUrl={wellsaBg}
            headerText="Wellsa: personal healthcare companion"
            headerClassName={classes.wellsaHeader}
            link="/our-work/wellsa"
            leftImage={<div className={classes.wellsaImage} />}
          />
          <ProjectTile
            backgroundColor="#094EA2"
            headerText={
              <>Interconnect: advance IT&nbsp;infrastructure for&nbsp;public transportation</>
            }
            headerClassName={classes.interconnectOneHeader}
            link="/our-work/interconnect-one"
            rightImage={<div className={classes.interconnectOneImage} />}
          />
          <ProjectTile
            backgroundColor="rgba(0, 0, 0, 0.24)"
            backgroundUrl={interconnectTwoBg}
            headerText={
              <>Interconnect: foster usage of&nbsp;public transportation, tourists included</>
            }
            headerClassName={classes.interconnectTwoHeader}
            link="/our-work/interconnect-two"
            rightImage={<div className={classes.interconnectTwoImage} />}
          />
          <ProjectTile
            backgroundColor="rgba(0, 0, 0, 0.15)"
            backgroundUrl={blockrulesBg}
            headerText={(
              <>
                BlockRules: the&nbsp;first comprehensive,
                regulatory compliant blockchain securities platform
              </>
            )}
            headerClassName={classes.blockrulesHeader}
            link="/our-work/blockrules"
            leftImage={<div className={classes.blockrulesLeft} />}
            rightImage={<div className={classes.blockrulesRight} />}
          />
          <ProjectTile
            backgroundUrl={ampBg}
            headerText={(
              <>AMP: one-stop portal for resellers of Cisco data center products</>
            )}
            headerClassName={classes.ampHeader}
            link="/our-work/amp"
            leftImage={<div className={classes.ampLeft} />}
            rightImage={<div className={classes.ampRight} />}
          />
          <ProjectTile
            backgroundColor="rgba(0, 0, 0, 0.15)"
            backgroundUrl={brandingBg}
            headerText={(
              <>Branding and design services</>
            )}
            headerClassName={classes.brandingHeader}
            link="/our-work/branding"
            rightImage={<div className={classes.brandingImage} />}
          />
        </div>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
