import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Footer from '../Footer';
import Nav from '../Nav';
import interconnectTwoBg from '../assets/interconnect-two-bg.jpg';
import interconnectTwoTop from '../assets/interconnect-two-top.png';
import interconnectTwoMiddle from '../assets/interconnect-two-middle.png';
import interconnectTwoBottom from '../assets/interconnect-two-bottom.png';
import interconnectTwoBottomTwo from '../assets/interconnect-two-bottom-two.png';
import interconnectEu from '../assets/interconnect-eu.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${interconnectTwoBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 415,
    height: 841,
    backgroundImage: `url(${interconnectTwoTop})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    flexShrink: 0,
    margin: '60px 80px -60px',
    '@media (max-width: 1500px)': {
      margin: '60px 40px -60px',
    },
    '@media (max-width: 1200px)': {
      margin: '40px auto',
      width: 240,
      height: 485,
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 240,
    marginLeft: 40,
    maxWidth: 1000,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1500px)': {
      marginLeft: 0,
    },
    '@media (max-width: 1200px)': {
      margin: '0 0 20px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  quoteSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 160,
    paddingBottom: 60,
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  quoteText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  quoteAuthor: {
    fontSize: 21,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  challenge: {
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  challengeTextContainer: {
    width: '50%',
    maxWidth: 660,
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 40,
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  middleImage: {
    width: 'calc(100% - 660px)',
    maxWidth: 1013,
    marginTop: 30,
    marginRight: -80,
    marginLeft: 'auto',
    '@media (max-width: 1700px)': {
      width: '50%',
    },
    '@media (max-width: 1500px)': {
      marginRight: -40,
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  middleImageMobile: {
    display: 'none',
    width: '100%',
    margin: '30px 0 -100px',
    '@media (max-width: 1200px)': {
      display: 'block',
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    width: '50%',
    maxWidth: 660,
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  bottomImages: {
    width: 'calc(100% - 510px)',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginRight: -160,
    '@media (max-width: 1500px)': {
      marginRight: -80,
    },
    '@media (max-width: 1200px)': {
      width: '100%',
      marginRight: -16,
    },
  },
  bottomImage: {
    width: 'calc(100% - 200px)',
    maxWidth: 617,
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 40,
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  bottomImageMobile: {
    width: '100%',
    marginTop: 10,
    display: 'none',
    '@media (max-width: 1200px)': {
      display: 'block',
    },
  },
  bottomImageTwo: {
    width: '100%',
    maxWidth: 1105,
    marginTop: -90,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      margin: '10px 0 0',
    },
  },
  link: {
    display: 'inline-block',
    fontSize: 21,
    fontWeight: 300,
    color: '#1388AA',
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  euImage: {
    width: '100%',
    maxWidth: 557,
    marginBottom: 20,
  },
});

export default function InterconnectTwo() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Interconnect: foster usage of public transportation, tourists included">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              Interconnect: foster usage of public transportation, tourists included
            </Typography>
          </div>
        </Section>
        <Section className={classes.quoteSection}>
          <Typography className={classes.quoteText}>
            “Toolla helped us design, implement, manufacture and test all the individual
            software and hardware elements for the new ticket validation technology. Toolla
            participated in all stages of the transnational INTERCONNECT system development
            and fully proved its knowledge about wireless technologies, IoT, embedded software,
            user interface design, web and mobile technologies, and software architectures.
            It was a pleasure for InnoBaltica to work on this agile and complicated project
            together with Toolla.”
          </Typography>
          <Typography className={classes.quoteAuthor}>
            Sylwia Wymysłowska
            <br />
            Project manager, InnoBaltica
          </Typography>
          <img src={interconnectTwoMiddle} alt="" className={classes.middleImageMobile} />
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              InnoBaltica Sp. z o. o., a leading developer of an integrated public
              transportation ticketing solution in Pomorskie Region in Poland, strives to
              encourage more visitors to explore the region by utilizing public transportation,
              and to foster regional public transportation in general. To this end, InnoBaltica
              has envisioned a ticket-purchasing solution allowing the passenger to be charged
              correct fare for any public transportation ride with a single tap on his or her
              mobile device, without a need to check in/out at a validating device on
              entering/exiting a bus or train, for a more convenient and safer travel. The
              technology was to be made available as a mobile app, which would additionally
              allow passengers (including visitors) to use packages for discounted, easy access
              to public transportation and regional attractions, and to plan their trips using
              public transport modes.
            </Typography>
          </div>
          <img src={interconnectTwoMiddle} alt="" className={classes.middleImage} />
          <img src={interconnectTwoBottom} alt="" className={classes.bottomImageMobile} />
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              Toolla was tasked with architecting and developing the new technology enabling
              one-click ticket purchase, as well as designing and implementing all the supporting
              mobile and web applications.
            </Typography>
            <Typography className={classes.sectionText}>
              We have researched using Bluetooth Low Energy (BLE) beacons and mobile devices
              to localize passengers inside public transportation vehicles and in the process
              designed and developed full-custom printed circuit boards (PCBs) and embedded
              software for smart BLE beacons, which also communicated with the Interconnect
              system through the cellular network. Complementing the beacons, we developed
              advanced trip detection algorithms within the mobile apps and the supporting
              cloud systems to correctly identify ongoing and completed trips, match them with
              schedules and eliminate any false detections. During the project, we designed
              beacon housings, manufactured a series of prototype beacons, and successfully
              performed test installations in buses owned by Gryf, a regional bus transport
              provider.
            </Typography>
            <Typography className={classes.sectionText}>
              Additionally, we designed and developed passenger mobile and web apps which allow
              passengers to plan public transportation-based trips, guide them during ongoing
              trips, allow them to use electronic cards for discounted access to regional
              attractions, and to access public transport tickets. The system comes with web
              portals for managing the contents and distribution of discount cards and packages,
              for managing the smart IoT beacon network, mobile apps for servicing the beacons,
              and apps for inspecting electronic tickets and cards -  all implemented by us.
            </Typography>
            <img src={interconnectEu} alt="" className={classes.euImage} />
            <Link
              href="https://opendata.info.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              opendata.info.pl
            </Link>
          </div>
          <div className={classes.bottomImages}>
            <img src={interconnectTwoBottom} alt="" className={classes.bottomImage} />
            <img src={interconnectTwoBottomTwo} alt="" className={classes.bottomImageTwo} />
          </div>
        </Section>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
