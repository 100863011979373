import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import Home from './Home/Home';
import ScrollToTop from './ScrollToTop';
import OurWork from './OurWork/OurWork';
import Tanbuu from './OurWork/Tanbuu';
import Wellsa from './OurWork/Wellsa';
import InterconnectOne from './OurWork/InterconnectOne';
import InterconnectTwo from './OurWork/InterconnectTwo';
import BlockRules from './OurWork/BlockRules';
import Amp from './OurWork/Amp';
import Branding from './OurWork/Branding';

export default function App() {
  return (
    <div>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/our-work" exact component={OurWork} />
          <Route path="/our-work/tanbuu" exact component={Tanbuu} />
          <Route path="/our-work/wellsa" exact component={Wellsa} />
          <Route path="/our-work/interconnect-one" exact component={InterconnectOne} />
          <Route path="/our-work/interconnect-two" exact component={InterconnectTwo} />
          <Route path="/our-work/blockrules" exact component={BlockRules} />
          <Route path="/our-work/amp" exact component={Amp} />
          <Route path="/our-work/branding" exact component={Branding} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
