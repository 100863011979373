import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Nav from '../Nav';
import Footer from '../Footer';
import interconnectOneTop from '../assets/interconnect-one-top.png';
import interconnectOneMiddle from '../assets/interconnect-one-middle.png';
import interconnectOneBottom from '../assets/interconnect-one-bottom.png';
import interconnectEu from '../assets/interconnect-eu.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: '#094EA2',
    position: 'relative',
  },
  topContent: {
    display: 'flex',
    paddingTop: 100,
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      paddingTop: 60,
    },
  },
  topImage: {
    maxWidth: 710,
    width: 'calc(100vw - 700px)',
    height: 600,
    backgroundImage: `url(${interconnectOneTop})`,
    backgroundSize: '744px 600px',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    flexShrink: 0,
    margin: '60px 50px -55px -160px',
    '@media (max-width: 1500px)': {
      margin: '60px 50px -55px -80px',
    },
    '@media (max-width: 1200px)': {
      height: 464,
      backgroundSize: '575px 464px',
      width: 'calc(100% - 20px)',
      margin: '0 0 30px -16px',
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 160,
    maxWidth: 900,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1760px)': {
      marginTop: 60,
    },
    '@media (max-width: 1200px)': {
      margin: '0 0 20px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  quoteSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 160,
    paddingBottom: 60,
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  quoteText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  quoteAuthor: {
    fontSize: 21,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  challenge: {
    paddingTop: 50,
    paddingBottom: 50,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      marginTop: 0,
      flexDirection: 'column',
    },
  },
  challengeTextContainer: {
    maxWidth: 670,
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 40,
    maxWidth: 630,
    '&:last-child': {
      marginBottom: 0,
    },
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    maxWidth: 670,
    paddingRight: 60,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
    },
  },
  middleImage: {
    backgroundImage: `url(${interconnectOneMiddle})`,
    backgroundSize: '1301px 660px',
    backgroundRepeat: 'no-repeat',
    width: 'calc(100vw - 820px)',
    maxWidth: 1130,
    height: 660,
    marginTop: 56,
    marginBottom: 40,
    marginRight: -160,
    marginLeft: 'auto',
    flexShrink: 0,
    '@media (max-width: 1500px)': {
      width: 'calc(100vw - 660px)',
      marginRight: -80,
    },
    '@media (max-width: 1200px)': {
      width: '100%',
      height: 'calc(660 / 1301 * 100vw)',
      margin: '30px 0 0',
      backgroundSize: 'contain',
    },
  },
  bottomImage: {
    maxWidth: 850,
    width: '50%',
    marginTop: 30,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      width: '100%',
      margin: '30px 0 0',
    },
  },
  link: {
    display: 'inline-block',
    fontSize: 21,
    fontWeight: 300,
    color: '#1388AA',
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  euImage: {
    width: '100%',
    maxWidth: 557,
    marginBottom: 20,
  },
});

export default function InterconnectOne() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Interconnect: advance IT infrastructure for public transportation">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              Interconnect: advance IT infrastructure for public transportation
            </Typography>
          </div>
        </Section>
        <Section className={classes.quoteSection}>
          <Typography className={classes.quoteText}>
            “Daily cooperation with the Toolla team was exemplary. Excellent communication
            ensured both full information exchange and effective design and development of
            the IT solutions. As part of this cooperation, a new technology of “contactless
            ticket verification” based on intelligent Bluetooth beacons has been worked out,
            tested, and prepared for broad implementation in regional and cross-border
            transportation systems.”
          </Typography>
          <Typography className={classes.quoteAuthor}>
            Sylwia Wymysłowska
            <br />
            Project manager, InnoBaltica
          </Typography>
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              Innobaltica Sp. z o. o., a leader in innovative solutions for public
              transportation from Pomorskie Region in Poland, found that many public transport
              companies operating outside the main cities in the region do not provide schedule
              information in standard formats such as GTFS or NeTEx. Consequently, it is not
              possible to use mobile apps or other online tools to plan trips based on public
              transportation in these locations. The goal is to eliminate such digital exclusion
              zones.
            </Typography>
          </div>
          <div className={classes.middleImage} />
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              Innobaltica teamed up with us to develop a web portal for managing and
              providing transportation data (including real-time data) in up-to-date
              digital formats, as well as analyzing and optimizing transport operations.
              The system is available to all public transport providers in Pomorskie Region, Poland.
            </Typography>
            <Typography className={classes.sectionText}>
              Unexpectedly, during the initial
              {' '}
              <span className={classes.nowrap}>Covid-19</span>
              {' '}
              lockdown, the system helped regional authorities to monitor public
              transportation coverage in rural areas.
            </Typography>
            <img src={interconnectEu} alt="" className={classes.euImage} />
            <Link
              href="https://opendata.info.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              opendata.info.pl
            </Link>
          </div>
          <img src={interconnectOneBottom} alt="" className={classes.bottomImage} />
        </Section>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
