import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface StyleProps {
  backgroundColor?: string;
  backgroundUrl?: string;
}

interface Props {
  headerText: React.ReactNode;
  headerClassName: string;
  link: string;
  leftImage?: React.ReactNode;
  rightImage?: React.ReactNode;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    margin: '27px auto',
    width: '100%',
    maxWidth: 1600,
    height: 625,
    backgroundColor: (props) => props.backgroundColor,
    position: 'relative',
    display: 'flex',
    textDecoration: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: (props) => (props.backgroundUrl ? `url(${props.backgroundUrl})` : 'none'),
      backgroundSize: 'cover',
      zIndex: -1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
    '&:hover::after': {
      opacity: 1,
    },
    '&:hover $projectMore': {
      opacity: 1,
    },
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      margin: '12px auto',
    },
  },
  projectText: {
    zIndex: 2,
    width: '50%',
    paddingLeft: 40,
    paddingRight: 40,
    order: 2,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  projectHeader: {
    fontSize: 50,
    color: 'white',
    fontWeight: 300,
    lineHeight: 1.32,
    '@media (max-width: 1200px)': {
      fontSize: 27,
      paddingTop: 30,
    },
  },
  projectMore: {
    display: 'inline-block',
    fontSize: 30,
    color: 'white',
    fontWeight: 300,
    textDecoration: 'none',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    '@media (max-width: 1200px)': {
      fontSize: 20,
      marginBottom: 40,
    },
  },
}));

export default function ProjectTile(props: StyleProps & Props) {
  const {
    backgroundColor, backgroundUrl, headerClassName, headerText, link, leftImage, rightImage,
  } = props;
  const classes = useStyles({ backgroundColor, backgroundUrl });
  return (
    <Link to={link} className={classes.root}>
      {leftImage}
      <div className={classes.projectText}>
        <Typography className={classNames(classes.projectHeader, headerClassName)}>
          {headerText}
        </Typography>
        <div className={classes.projectMore}>
          Find out more
        </div>
      </div>
      {rightImage}
    </Link>
  );
}

ProjectTile.defaultProps = {
  backgroundColor: '',
  backgroundUrl: '',
  leftImage: null,
  rightImage: null,
};
