import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';
import Section from '../Section';
import Footer from '../Footer';
import Nav from '../Nav';
import wellsaBg from '../assets/wellsa-bg.jpg';
import wellsaTop from '../assets/wellsa-top.png';
import wellsaBottom from '../assets/wellsa-bottom.png';

const useStyles = makeStyles({
  top: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${wellsaBg})`,
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  topContent: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  topImage: {
    width: 415,
    height: 836,
    backgroundImage: `url(${wellsaTop})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    flexShrink: 0,
    margin: '60px 80px -210px',
    '@media (max-width: 1200px)': {
      margin: '40px auto',
      width: 240,
      height: 485,
    },
  },
  topHeader: {
    fontSize: 80,
    fontWeight: 700,
    color: 'white',
    marginTop: 200,
    maxWidth: 900,
    lineHeight: 1.325,
    textShadow: '0 3px 20px rgba(0, 0, 0, 0.11)',
    '@media (max-width: 1200px)': {
      margin: '0 0 20px',
      fontSize: 44,
      textShadow: '0 3px 20px rgba(0, 0, 0, 0.58)',
    },
  },
  quoteSection: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 300,
    paddingBottom: 60,
    '@media (max-width: 1200px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  quoteText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  quoteAuthor: {
    fontSize: 21,
    marginLeft: 'auto',
    '@media (max-width: 1200px)': {
      fontSize: 16,
      marginLeft: 0,
    },
  },
  challenge: {
    marginTop: 0,
    marginBottom: 70,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 70,
      marginTop: 0,
      marginBottom: 25,
    },
  },
  challengeTextContainer: {
    width: '50%',
    '@media (max-width: 1200px)': {
      width: '100%',
    },
  },
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 25,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  sectionText: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 10,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  solution: {
    paddingTop: 65,
    paddingBottom: 100,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundImage: 'linear-gradient(180deg, rgba(247, 250, 255, 1) 0%, rgba(237, 238, 251, 1) 100%)',
    '@media (max-width: 1200px)': {
      paddingTop: 30,
      paddingBottom: 30,
      flexDirection: 'column',
    },
  },
  solutionTextContainer: {
    width: '50%',
    paddingRight: 40,
    '@media (max-width: 1200px)': {
      width: '100%',
      paddingRight: 0,
      order: 2,
    },
  },
  bottomImage: {
    width: '65%',
    maxWidth: 816,
    marginTop: -280,
    marginRight: -40,
    marginLeft: 'auto',
    '@media (max-width: 1500px)': {
      marginTop: -240,
    },
    '@media (max-width: 1200px)': {
      width: '100%',
      margin: '-110px 0 30px',
    },
  },
});

export default function Wellsa() {
  const classes = useStyles();
  return (
    <DocumentTitle title="Wellsa: a personal healthcare companion">
      <div>
        <Section className={classes.top}>
          <Nav />
          <div className={classes.topContent}>
            <div className={classes.topImage} />
            <Typography className={classes.topHeader}>
              Wellsa: a personal healthcare companion
            </Typography>
          </div>
        </Section>
        <Section className={classes.quoteSection}>
          <Typography className={classes.quoteText}>
            “Toolla.net provided off shore engineering services for both of my startups
            Wellsa.me and Blockrules.com. The team I worked with has provided exceptional
            software engineering support. They are expertise in full stack - web, mobile app
            and micro services development. Toolla team is very professional. They always
            delivered projects on time and the quality of work is exceptional. I would definitely
            hire their team if I need off shore engineering services in the future.”
          </Typography>
          <Typography className={classes.quoteAuthor}>
            Pramod Madabhushi
            <br />
            VP Engineering
          </Typography>
        </Section>
        <Section className={classes.challenge}>
          <div className={classes.challengeTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Challenge
            </Typography>
            <Typography className={classes.sectionText}>
              Wellsa Corp. has identified certain fundamental challenges in today’s
              healthcare. First, availability of medical data and records often do not
              translate into relevant information about our health. Second, knowing health
              information is often not enough to make us comply with health recommendations.
              Finally, a desire to comply with these recommendations does not mean we can
              afford it.
            </Typography>
          </div>
        </Section>
        <Section className={classes.solution}>
          <div className={classes.solutionTextContainer}>
            <Typography className={classes.sectionHeader}>
              The Solution
            </Typography>
            <Typography className={classes.sectionText}>
              These key issues has been tackled by Wellsa personal healthcare companion in
              the form of a mobile app. We have helped design and implement the entire solution,
              including an expert system based on non-Boolean logic which uses various sources
              of medical data (biometrics, records) as well as a conversational interface to
              help detect medical conditions and provide advice.  We have developed features
              (e.g. pillbox, health goals trackers) to help one comply with health
              recommendations. Finally, we have developed custom latent-factor collaborative
              filtering + knowledge-based recommender system to provide affordable options to
              support personal health goals.
            </Typography>
          </div>
          <img src={wellsaBottom} alt="" className={classes.bottomImage} />
        </Section>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
