import React, { useEffect } from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import scrollIntoView from 'scroll-into-view';
import { useLocation } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import backgroundTwo from '../assets/background-two.jpg';
import FirstSection from './FirstSection';
import Customers from './Customers';
import Footer from '../Footer';

const useStyles = makeStyles({
  sectionHeader: {
    fontSize: 35,
    fontWeight: 700,
    marginBottom: 24,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  aboutUs: {
    height: 700,
    padding: '40px 160px',
    backgroundImage: `url(${backgroundTwo})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media (max-width: 1500px)': {
      padding: '40px 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '40px 16px',
      height: 640,
    },
  },
  sectionHeaderWhite: {
    fontSize: 35,
    fontWeight: 700,
    color: 'white',
    marginBottom: 24,
    '@media (max-width: 1200px)': {
      fontSize: 27,
    },
  },
  aboutUsContent: {
    fontSize: 21,
    fontWeight: 300,
    color: 'white',
    textShadow: '1px 1px 2px #002f35',
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  ourServices: {
    backgroundColor: 'rgba(126, 156, 162, 0.09)',
    padding: '40px 160px',
    '@media (max-width: 1500px)': {
      padding: '40px 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '40px 16px',
    },
  },
  servicesContent: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 24,
    color: 'black',
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  servicesList: {
    fontSize: 21,
    fontWeight: 300,
    listStyle: 'none',
    paddingLeft: 0,
    color: 'black',
    '& li': {
      marginBottom: 8,
    },
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  careers: {
    backgroundColor: 'white',
    padding: '40px 160px',
    color: 'black',
    '@media (max-width: 1500px)': {
      padding: '40px 80px',
    },
    '@media (max-width: 1200px)': {
      padding: '40px 16px',
    },
  },
  careersText: {
    marginBottom: 32,
    fontSize: 21,
    fontWeight: 300,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  opening: {
    marginBottom: 32,
    fontSize: 21,
    fontWeight: 300,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  openingLink: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
  openingDetails: {
    fontSize: 21,
    fontWeight: 300,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
  readyToJoin: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: 16,
    '@media (max-width: 1200px)': {
      fontSize: 16,
    },
  },
});

export default function Home() {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    const el = document.getElementById(location.hash.substring(1));
    if (el) {
      scrollIntoView(el, { align: { top: 0 } });
    }
  }, [location]);
  return (
    <DocumentTitle
      title="Mobile &amp; Web App Development Company - Android &amp; iOS - USA, Poland | Toolla"
    >
      <div>
        <FirstSection />
        <Customers />
        <div className={classes.aboutUs} id="about-us">
          <Typography className={classes.sectionHeaderWhite}>
            About Us
          </Typography>
          <Typography className={classes.aboutUsContent}>
            Toolla is a web and mobile app development startup with extensive expertise
            in automated AI-based services and systems for collaboration, public transportation,
            healthcare, and digital marketing.Toolla was founded in California in 2015 by
            engineers with PhDs from top universities including MIT, U. Michigan, and
            U.C. Berkeley. We are well-experienced in developing web, iOS and Android
            applications, custom AI / machine-learning based services, and IoT solutions.
            Our team combines cutting-edge UI and UX designers, programmers and wireless
            communications engineers having experience with meeting customer&apos;s
            design requirements. We have a combined experience of 30+ years in working with
            Fortune 500 companies. We are holders of multiple US patents (granted and pending)
            in high-tech.
          </Typography>
        </div>
        <div className={classes.ourServices} id="services">
          <Typography className={classes.sectionHeader}>
            Our Services
          </Typography>
          <Typography className={classes.servicesContent}>
            Toolla understands the needs of the customer and develops apps from scratch that
            will be personalized to the specific needs of the customer.  The team works in close
            cooperation with the customer at every stage of the development process.  We are
            a self-reliant team, that will minimize customer responsibilities.  Dedicated team
            guarantees prompt response and timely delivery process. Our services include:
          </Typography>
          <ul className={classes.servicesList}>
            <li>
              mobile & web app development
            </li>
            <li>
              UX / UI design
            </li>
            <li>
              development of AI / machine learning based systems
            </li>
            <li>
              design and manufacturing of custom IoT devices and solutions
            </li>
            <li>
              embedded software development
            </li>
            <li>
              branding elements design
            </li>
          </ul>
        </div>
        <div className={classes.careers} id="careers">
          <Typography className={classes.sectionHeader}>
            Careers
          </Typography>
          <Typography className={classes.careersText}>
            Our current openings:
          </Typography>
          <div className={classes.opening}>
            <Link
              href="/javascript-developer.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.openingLink}
            >
              Web Frontend Application Engineer
            </Link>
            <Typography className={classes.openingDetails}>
              Gdansk, Poland, Full-time
            </Typography>
          </div>
          <div className={classes.opening}>
            <Link
              href="/backend-developer.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className={classes.openingLink}
            >
              Backend Engineer
            </Link>
            <Typography className={classes.openingDetails}>
              Gdansk, Poland, Full-time
            </Typography>
          </div>
          <Typography className={classes.readyToJoin}>
            Ready to join? Please send your resume to info@toolla.net
          </Typography>
        </div>
        <Footer />
      </div>
    </DocumentTitle>
  );
}
